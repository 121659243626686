import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import Digilab from "../Demo/digilab";

import firebase from 'firebase/app'
// import "firebase/auth";
import "firebase/database";
import Service from '../Demo/service';
// import "firebase/firestore";
// import "firebase/functions";
// import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAEYZ0NylFt1lQU6iO9vy-f5sCFY1c_fZs",
  authDomain: "faba-981f9.firebaseapp.com",
  databaseURL: "https://faba-981f9-default-rtdb.firebaseio.com",
  projectId: "faba-981f9",
  storageBucket: "faba-981f9.appspot.com",
  messagingSenderId: "943224752102",
  appId: "1:943224752102:web:9bcb7627ccc12ae901b827"
  };
  

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});
const Layout = Loadable({
    loader: () => import('../Demo/Authentication/SignIn/SignIn1'),
    loading: Loader
});
let AllChauffeur = []
class App extends Component {

    componentDidMount() {
        if (!firebase.apps.length) {
          firebase.initializeApp(firebaseConfig);
        }
        firebase.database().ref('chauffeur').on('value', async (snapshot) => {
          let value = snapshot.val();
            if(value !== null && value !== undefined){
              let tab = []
              await Object.values(value).forEach(element => {
                let b = Object.values(element)
                if(b.length === 1) {
                    tab.push(b[0])
                } else {
                  b.forEach(e => {
                      tab.push(e)
                  });
                }
                // Object.assign(tab, element)
              });
              AllChauffeur = tab;
              tab = [];
            } else {
              Service.ListUtilisateur("Chauffeur").then(
                (response) => {
                  AllChauffeur = response.data.filter((e) => {
                    return e.produit !== undefined;
                  })
                });
            }
          });
          setInterval(() => {
            Digilab.getLocation("*").then(
              async (response) => {
                if(AllChauffeur.length > 0 && response.data !== undefined) {
                  await AllChauffeur.forEach(element => {
                    if(element.gps !== undefined) {
                    let location2 = response.data.filter((e) => {
                      return e.imei.toString().toLocaleLowerCase().match(element.gps.toLocaleLowerCase());
                    });
                    let location = location2[0];
                    if(location !== undefined && element.gps !== undefined) {
                      firebase
                        .database()
                        .ref('chauffeur/' + element.type + '/' + element.id)
                        .set({
                          lat: parseFloat(location.lastCoordinate.latitude),
                          lng: parseFloat(location.lastCoordinate.longitude),
                          nom: element.prenom ? (element.prenom + " " + element.nom) : element.nom,
                          telephone: element.telephone,
                          immatriculation: element.immatriculation ? element.immatriculation : "",
                          photo: element.photo ? element.photo : "",
                          image: element.image ? element.image : "",
                          image2: element.image2 ? element.image2 : "",
                          image3: element.image3 ? element.image3 : "",
                          image4: element.image4 ? element.image4 : "",
                          produitid: element.produitid ? element.produitid : "",
                          id: element.id,
                          type: element.type,
                          disponible: element.disponible ? element.disponible : true,
                          gps: element.gps,
                        });
                    }
                  }
                  });
                }
              })
          }, 5000);
      }

    render() {
        const menu = routes.map((route, index) => {
          return (route.component) ? (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  )} />
          ) : (null);
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {/* {menu} */}
                            <Route path="/admin" component={AdminLayout} />
                            <Route path="/" component={Layout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

export default App;
