import React from 'react';

const SignUp1 = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp1'));
// const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));
// const home = React.lazy(() => import('./Demo/home/home.js'));
// const index = React.lazy(() => import('./Demo/home/index.js'));

const route = [
    // { path: '/', exact: true, name: 'accueil', component: index },
    // { path: '/home/:type', exact: true, name: 'home', component: home },
    { path: '/auth/inscription', exact: true, name: 'Signup 1', component: SignUp1 },
    // { path: '/auth/connexion', exact: true, name: 'Signin 1', component: Signin1 }
];

export default route;