import axios from 'axios'
import { Color } from './color';
export const header={
  'Access-Control-Allow-Origin' : "*",
  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  'mode': 'no-cors'
};



const url = 'https://vps82722.serveur-vps.net:2474/gps/gps/all'
// const url = 'http://185.98.139.77:9090/gps/gps/all'
// const cors = "https://corsanywhere.herokuapp.com/"
class Digilab {
      constructor() {
      }
      public static getLocation(gps: String) {
        // return axios.get(cors + url + `/api/api.php?api=user&key=${Color.keyDigilab}&cmd=OBJECT_GET_LOCATIONS,${gps}`,
        return axios.get(url,
          { 

          }
        );
      }
    }
export default Digilab;