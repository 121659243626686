import axios from 'axios'

export const header = {'Authorization': `Basic ${localStorage.getItem('token') !== null ? localStorage.getItem('token') : ""}`};


// const url = 'http://localhost:8080'
// const url = 'http://185.98.139.77:9090/faba'
const url = 'https://vps82722.serveur-vps.net:2474/faba'
const urlgps = 'https://vps82722.serveur-vps.net:2474/gps'
// const urlgps = 'http://185.98.139.77:9090/gps'

class Service {
    constructor() {
    }

    public static inscription(data: any) {
        return axios.post(url + '/anonyme/inscription', data);
    }


    public static ListCategoriePrincipaleTypePartenaire(type: any) {
        return axios.post(url + '/anonyme/ListCategoriePrincipaleTypePartenaire', type);
    }

    public static addProduit(data: any) {
        return axios.post(url + '/users/addProduit', data, {headers: header});
    }

    public static addCategorie(data: any) {
        return axios.post(url + '/users/addCategorie', data, {headers: header});
    }

    public static addCategoriePrincipale(data: any) {
        return axios.post(url + '/users/addCategoriePrincipale', data, {headers: header});
    }

    public static dupliquerCategoriePrincipale(data: any) {
        return axios.post(url + '/users/dupliquerCategoriePrincipale', data, {headers: header});
    }

    public static addEntrant(data: any) {
        return axios.post(url + '/users/addEntrant', data, {headers: header});
    }

    public static addBoutique(data: any) {
        return axios.post(url + '/users/addBoutique', data, {headers: header});
    }

    public static addPub(data: any) {
        return axios.post(url + '/users/addPub', data, {headers: header});
    }

    public static deletePub(id: any) {

        return axios.delete(url + '/users/deletePub/' + id, {headers: header});
    }

    public static ListeEntreprise(email: any) {

        return axios.get(url + '/users/ListEntreprise/' + email, {headers: header});
    }

    public static ListeProjet(email: any) {
        return axios.get(url + '/users/ListeProjet/' + email, {headers: header});
    }

    public static afficherAllCommande(data: any) {
        return axios.post(url + '/users/afficherAllCommande', data, {headers: header});
    }

    public static afficherAllEntrer(data: any) {
        return axios.post(url + '/users/afficherAllEntrer', data, {headers: header});
    }

    public static VoirEtat(data: any) {
        return axios.post(url + '/users/VoirEtat', data, {headers: header});
    }

    public static afficherAllCommandeJour(etat: String) {
        return axios.get(url + '/users/afficherAllCommandeJour/' + etat, {headers: header});
    }

    public static validerPaiement(id: number) {
        return axios.get(url + '/users/validerPaiement/' + id, {headers: header});
    }

    public static livrer(id: number) {
        return axios.post(url + '/users/livrer', {id: id}, {headers: header});
    }

    public static changeEtat(id: number, etat: String) {
        return axios.post(url + '/users/changeEtat', {id: id, etat: etat}, {headers: header});
    }

    public static Attribuer(data: any) {
        return axios.post(url + '/users/Attribuer', data, {headers: header});
    }

    public static async afficher(data: any) {
        return axios.post(url + '/users/afficher', data, {headers: header});
    }

    public static async afficherJour(etat: String, id: number) {
        return axios.get(url + '/users/afficherJour/' + etat + "/" + id, {headers: header});
    }


    public static afficherDemande(data: any) {
        return axios.post(url + '/users/afficherDemande', data, {headers: header});
    }

    public static updateEtatProjet(data: any) {
        return axios.put(url + '/users/updateEtatProjet', data, {headers: header});
    }

    public static updateEtatEmprunt(data: any) {
        return axios.put(url + '/users/updateEtatEmprunt', data, {headers: header});
    }

    public static StateSolde(email: any) {
        return axios.get(url + '/users/StateSolde/' + email, {headers: header});
    }

    public static getUtilisateur(nomUtilisateur: any) {
        return axios.get(url + '/portemonnaieelectronique/utilisateur/' + nomUtilisateur, {headers: header});
    }

    public static ListeEmpruntEntreprise(id: number) {
        return axios.get(url + '/users/ListeEmpruntEntreprise/' + id, {headers: header});
    }

    public static changeVisibilite(id: number) {
        return axios.get(url + '/users/changeVisibilite/' + id, {headers: header});
    }

    public static changeVisibiliteCategoriePrincipale(id: number) {
        return axios.get(url + '/users/changeVisibiliteCategoriePrincipale/' + id, {headers: header});
    }

    public static changeVisibiliteCategorie(id: number) {
        return axios.get(url + '/users/changeVisibiliteCategorie/' + id, {headers: header});
    }

    public static changeVisibiliteBoutique(id: number) {
        return axios.get(url + '/users/changeVisibiliteBoutique/' + id, {headers: header});
    }

    public static getEntrepriseMembre(id: number) {
        return axios.get(url + '/users/getEntrepriseMembre/' + id, {headers: header});
    }

    public static ListeProjetEntreprise(id: number) {
        return axios.get(url + '/users/ListeProjetEntreprise/' + id, {headers: header});
    }

    public static addRole(idRole: number, idUtilisateur: number) {
        return axios.get(url + '/users/addRole/' + idRole + "/" + idUtilisateur, {headers: header});
    }

    public static removeRoleUser(idRole: number, idUtilisateur: number) {
        return axios.get(url + '/users/removeRoleUser/' + idRole + "/" + idUtilisateur, {headers: header});
    }

    public static ListUtilisateur(type: String) {
        return axios.get(url + '/users/ListUtilisateur/' + type, {headers: header});
    }

    public static ListUtilisateur2(type: String, role: String) {
        return axios.get(url + '/users/ListUtilisateur2/' + type + "/" + role, {headers: header});
    }

    public static AllRoles() {
        return axios.get(url + '/users/AllRoles', {headers: header});
    }

    public static ListProduitByCategoriePrincipaleType(type: any) {
        return axios.post(url + '/anonyme/ListProduitByCategoriePrincipaleType', type);
    }

    public static StateAdmin(data: any) {

        return axios.post(url + '/users/StateAdmin', data, {headers: header});
    }

    public static ListCommentaire() {
        return axios.get(url + '/users/ListCommentaire', {headers: header});
    }

    public static ListProduits() {
        return axios.get(url + '/users/ListProduits', {headers: header});
    }

    public static produitsList(page: number, size: number, nomProduit: String, nomCategorie: String, nomSousCategorie: String) {
        return axios.get(url + '/users/produitsList?page=' + page + '&size=' + size + '&nomProduit=' + nomProduit + '&nomCategorie=' + nomCategorie + '&nomSousCategorie=' + nomSousCategorie, {headers: header});
    }

    public static ListCategorie() {
        return axios.get(url + '/users/ListCategorie', {headers: header});
    }

    public static ListMessageSansReponse() {
        return axios.get(url + '/users/ListMessageSansReponse', {headers: header});
    }

    public static ListMessageUser(id: number) {
        return axios.get(url + '/anonyme/ListMessageUser/' + id).catch(function (error) {
            if (error.response.status === 403 || error.response.status === 401) {
                return axios.get(url + '/users/ListMessageUser/' + id);
            }
        });
        ;
    }

    public static ListCategoriePrincipale() {
        return axios.get(url + '/users/ListCategoriePrincipale', {headers: header});
    }

    public static sendMessage(data: any) {
        return axios.post(url + '/users/sendMessage', data, {headers: header});
    }

    public static ListBoutique() {
        return axios.get(url + '/users/ListBoutique', {headers: header});
    }

    public static SearchClienById(idclient: number) {
        return axios.get(url + '/users/SearchClienById/' + idclient, {headers: header});
    }

    public static ajouterAdminPartenaire(idclient: number, partenaireid: number) {
        return axios.get(url + '/users/ajouterAdminPartenaire/' + idclient + "/" + partenaireid, {headers: header});
    }

    public static removeAdminPartenaire(idclient: number, partenaireid: number) {
        return axios.get(url + '/users/removeAdminPartenaire/' + idclient + "/" + partenaireid, {headers: header});
    }

    public static ListBoutiqueValide() {
        return axios.get(url + '/users/ListBoutiqueValide', {headers: header});
    }

    public static Listpub() {
        return axios.get(url + '/anonyme/Listpub');
    }

    public static ListFaq() {
        return axios.get(url + '/anonyme/ListFaq');
    }

    public static ListClassement() {
        return axios.get(url + '/anonyme/ListClassement');
    }

    public static ListGPS() {
        return axios.get(urlgps + '/gps/all?paginate=false');
    }

    public static Listnotification() {
        return axios.get(url + '/anonyme/Listnotification');
    }

    public static ListProjetHome() {
        return axios.get(url + '/anonyme/ListProjetHome');
    }

    public static projetById(id: number) {
        return axios.get(url + '/anonyme/projetById/' + id);
    }

    public static projetByCategorie(type: String) {
        return axios.get(url + '/anonyme/projetByCategorie/' + type);
    }

    public static addMembreInEntreprise(idMembre: number, idEntreprise: number) {
        return axios.get(url + '/users/addMembreInEntreprise/' + idMembre + "/" + idEntreprise, {headers: header});
    }

    public static deleteMembreInEntreprise(idMembre: number, idEntreprise: number) {
        return axios.get(url + '/users/deleteMembreInEntreprise/' + idMembre + "/" + idEntreprise, {headers: header});
    }

    public static addEntreprise(data: any) {
        return axios.post(url + '/users/addEntreprise', data, {headers: header});
    }

    public static preterACeProjet(data: any) {
        return axios.post(url + '/users/preterACeProjet', data, {headers: header});
    }

    public static sendEmprunt(data: any) {
        return axios.post(url + '/users/sendEmprunt', data, {headers: header});
    }

    public static sendEmpruntanonyme(data: any) {
        return axios.post(url + '/anonyme/sendEmprunt', data);
    }

    public static sendProjet(data: any, idEmprunt: number) {
        return axios.post(url + '/users/sendProjet/' + idEmprunt, data, {headers: header});
    }

    public static Charge(data: any) {
        return axios.post(url + '/users/Charge', data, {headers: header});
    }

    public static Virement(data: any) {
        return axios.post(url + '/users/Virement', data, {headers: header});
    }

    public static debiter(data: any) {
        return axios.post(url + '/users/debiter', data, {headers: header});
    }

    public static filtrerUserTransaction(data: any) {
        return axios.post(url + '/users/filtrerUserTransaction', data, {headers: header});
    }

    public static updateUser(data: any) {
        return axios.put(url + '/users/updateUser', data, {headers: header});
    }

    public static addFAQ(data: any) {
        return axios.post(url + '/users/addFAQ', data, {headers: header});
    }

    public static addclassement(data: any) {
        return axios.post(url + '/users/addclassement', data, {headers: header});
    }

    public static addgps(data: any) {
        return axios.put(urlgps + '/gps', data, {headers: header});
    }

    public static addnotification(data: any) {
        return axios.post(url + '/users/addnotification', data, {headers: header});
    }

    public static updateUserInfoBanque(data: any) {
        return axios.put(url + '/users/updateUserInfoBanque', data, {headers: header});
    }

    public static connexion(token: any) {
        const HEADERS = {'Authorization': `Basic ${token}`};
        return axios.get(url + '/portemonnaieelectronique/login', {headers: HEADERS});
    }

    public static envoiCode(email: any, code: string) {
        return axios.post(url + '/anonyme/envoiCode', {email, code});
    }

    public static ModificationMotDePasse(email: any, motDePasse: string) {
        return axios.post(url + '/anonyme/modificationMotDePasse', {email, motDePasse});
    }

    public static getAbonnementsList(paginate: boolean, page: number, size: number, voiture: String, sousCategorie: String, partenaire: String) {
        return axios.get(url + '/users/abonnement?paginate=' + paginate + '&page=' + page + '&size=' + size + '&voiture=' + voiture + '&sousCategorie=' + sousCategorie + '&partenaire=' + partenaire, {headers: header});
    }

    public static saveReAbonnement(data: any) {
        return axios.post(url + '/users/reabonnement', data, {headers: header});
    }

}

export default Service;
